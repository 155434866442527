import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, fontSizes } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string
};

const Text = props => {
  const { className, children, color = colors.darkGray, size = 'medium', ...allProps } = props;

  return <Paragraph className={className} color={color} size={size} {...allProps}>{children}</Paragraph>
};

Text.propTypes = propTypes;

const Paragraph = styled.p`
  font-size: ${({ size }) => size === 'large' ? fontSizes.paragraph.large.fontSize : size === 'medium' ? fontSizes.paragraph.medium.fontSize : fontSizes.paragraph.small.fontSize};
  line-height: ${({ size }) => size === 'large' ? fontSizes.paragraph.large.lineHeight : size === 'medium' ? fontSizes.paragraph.medium.lineHeight : fontSizes.paragraph.small.lineHeight};
  color: ${({ color }) => color}
`;

export { Text };

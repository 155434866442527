import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, fontSizes } from '../styles';

const propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  level: PropTypes.number,
  size: PropTypes.number,
  bottomMargin: PropTypes.number,
  fontWeight: PropTypes.number
};

const headings = {
  1: styled.h1`
    ${({ styles }) => styles}
  `,
  2: styled.h2`
    ${({ styles }) => styles}
  `,
  3: styled.h3`
    ${({ styles }) => styles}
  `,
  4: styled.h4`
    ${({ styles }) => styles}
  `,
  5: styled.h5`
    ${({ styles }) => styles}
  `,
  6: styled.h6`
    ${({ styles }) => styles}
  `
};

const Heading = props => {
  const { className, children, level, size, color = colors.darkGray, bottomMargin = 0, fontWeight = 700 } = props;
  let headingLevel = level || size || 1;
  let headingSize = size || level || 1;
  let headingColor = color;

  if (headingLevel > 6) {
    headingLevel = 6;
  }

  if (headingSize > 6) {
    headingSize = 6;
  }

  const styles = `
    font-size: ${fontSizes[`h${headingSize}`].fontSize};
    line-height: ${fontSizes[`h${headingSize}`].lineHeight};
    margin: 0;
    margin-bottom: ${bottomMargin}px;
    font-weight: ${fontWeight};
    color: ${headingColor}
  `;

  return React.createElement(headings[headingLevel], { styles, className }, children);
};

Heading.propTypes = propTypes;

export { Heading };
